/* Container for the entire page */
.container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    /*margin: 20px auto;*/
}

/* Title styling */
.title {
    text-align: center;
    font-size: 24px;
    color: #0066cc;
    margin-bottom: 20px;
}

/* Row of buttons */
.buttonRow {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px; /* Space between buttons */
}

/* Toggle button styling */
.toggleButton {
    background-color: #f2f2f2;
    color: #333333;
    border: 2px solid #cccccc;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.toggleButton:hover {
    background-color: #ffdd00;
    border-color: #0066cc;
    color: #0066cc;
}

/* Active (selected) button styling */
.activeButton {
    background-color: #0066cc;
    color: white;
    border-color: #004c99;
}

/* Search box container */
.searchBoxContainer {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    margin-bottom:10px;
}

/* Search box styling */
.searchBox {
    width: 300px;
    padding: 10px;
    font-size: 14px;
    border: 2px solid #cccccc;
    border-radius: 4px;
    outline: none;
    transition: all 0.3s ease;
}

.searchBox:focus {
    border-color: #0066cc;
    box-shadow: 0 0 5px rgba(0, 102, 204, 0.5);
}





/* Table styling */
.table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.th {
    background-color: #0066cc;
    color: white;
    text-align: left;
    padding: 12px;
    font-weight: bold;
    font-size: 14px;
}

/* Row styling */
.tr {
    border-bottom: 1px solid #dddddd;
}

.tr:nth-child(even) {
    background-color: #f2f2f2;
}

.tr:hover {
    background-color: #ffdd00;
    cursor: pointer;
}

/* Cell styling */
.td {
    text-align: left;
    padding: 12px;
    color: #333333;
    font-size: 14px;
}




/* New Examinee Form */
/* Container for the form */
.container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #0066cc;
    margin-bottom: 20px;
}

/* Form element styling */
.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Form group */
.form_group {
    display: flex;
    flex-direction: column;
}

/* Label styling */
.label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333333;
}

/* Input styling */
.input {
    padding: 10px;
    font-size: 14px;
    border: 2px solid #cccccc;
    border-radius: 4px;
    outline: none;
    transition: all 0.3s ease;
}

.input:focus {
    border-color: #0066cc;
    box-shadow: 0 0 5px rgba(0, 102, 204, 0.5);
}

/* Submit button styling */
.submit_button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: #0066cc;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
}

.submit_button:hover {
    background-color: #005bb5;
}

.submit_button:active {
    background-color: #004c99;
}
.error_message {
    color:red;
}
.cancel_edit_button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.assign_tests_button {
    background-color: #ffdd00; /* Yellow color */
    color: #333333;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    transition: all 0.3s ease;
}

.assign_tests_button:hover {
    background-color: #e6c300;
}

.edit_button_group {
    margin-top: 20px;
    display: flex;
    justify-content: space-around; /* Even spacing between buttons */
}


/* EDIT MODAL STYLES */
/* Modal container to center the modal on the screen */
.modal_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it stays on top of everything */
}
/* Modal content styling */
.modal_content {
    position:relative;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 90%; /* Responsive for smaller screens */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease; /* Optional fade-in animation */
}
/* Close button for the modal */
.modal_close_button {
    background: none;
    border: none;
    color: #0066cc;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1001;
}
.modal_close_button:hover {
    color: #004c99;
}
/* Fade-in animation for modal */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
.modal_close_button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: #ff4d4d; /* Red background */
    color: white;
    border: none;
    font-size: 18px;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.modal_close_button:hover {
    background-color: #cc0000; /* Darker red on hover */
    transform: translateY(-2px);
}

.modal_close_button:active {
    background-color: #990000; /* Even darker red on active click */
    transform: translateY(0);
}

.examinee_info {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f2f2f2;
    border: 1px solid #cccccc;
    border-radius: 8px;
}

.test_list {
    margin-bottom: 30px;
}

.section_title {
    font-size: 20px;
    font-weight: bold;
    color: #624c0e;
    margin-bottom: 10px;
}

.list {
    list-style-type: none;
    padding: 0;
}

.list_item {
    padding: 10px;
    margin: 5px 0;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.list_item:hover {
    background-color: #e6e6e6;
}

.list_item_empty {
    color: #666666;
    font-style: italic;
    padding: 10px;
}


.test_name {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 5px;
}

.test_description {
    font-size: 14px;
    color: #666666;
    margin-bottom: 10px;
    display: none; /* Hidden by default */
}

.assign_test_button {
    display: none; /* Hidden by default */
    text-align: center;
    margin-top: 10px;
}

.assign_test_button button {
    background-color: #0066cc; /* Current theme blue */
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.assign_test_button button:hover {
    background-color: #004c99; /* Darker blue on hover */
    transform: translateY(-2px);
}

.assign_test_button button:active {
    background-color: #003366; /* Even darker on active click */
    transform: translateY(0);
}

.list_item_selected .test_description,
.list_item_selected .assign_test_button {
    display: block; /* Show when item is selected */
}

.list_item {
    cursor: pointer;
    padding: 15px;
    transition: background-color 0.3s ease;
}

.list_item:hover {
    background-color: #ffe680;
}
