.login_label {
    margin-right:5px;
    font-weight: bold;
    color: #007bff; /* Blue text for labels */
}
.login_form {
    display:flex;
    flex-direction:column;
    font-size:24pt;
    width:650px;
    padding: 20px;
    border: 1px solid #007bff; /* Subtle blue border */
    border-radius: 10px; /* Smooth edges for the form */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
    background-color: #f9f9f9; /* Light gray for form background */
}

.login_form input[type="text"],
.login_form input[type="password"] {
    width: 400px;
    padding: 10px;
    font-size: 22px;
    border: 2px solid #ddd; /* Subtle gray border */
    border-radius: 5px; /* Rounded corners */
    margin-bottom: 15px; /* Spacing between inputs */
    background-color: white; /* White background */
    color: #333; /* Dark text for readability */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

/* Focus effect for input fields */
.login_form input[type="text"]:focus,
.login_form input[type="password"]:focus {
    border-color: #007bff; /* Blue border on focus */
    box-shadow: 0 0 4px #007bff; /* Subtle blue glow */
    outline: none; /* Remove default focus outline */
}

/* Placeholder text styling */
.login_form input::placeholder {
    color: #aaa; /* Light gray placeholder text */
}

.login_row {
    display:flex;
    flex-direction:row;
    justify-content: end;
    width:600px;
    margin-bottom:5px;
}
/* Button Styling */
.login_button {
    background-color: #007bff; /* Blue primary background */
    color: white; /* White text for contrast */
    border: 2px solid #007bff; /* Border matching the button background */
    padding: 10px 20px; /* Comfortable padding */
    font-size: 22px; /* Readable font size */
    font-weight: bold; /* Emphasized text */
    border-radius: 5px; /* Smooth, modern edges */
    cursor: pointer; /* Pointer cursor on hover */
    transition: all 0.3s ease; /* Smooth transition effects */
}

/* Hover effect */
.login_button:hover {
    background-color: #0056b3; /* Darker blue for hover effect */
    border-color: #0056b3; /* Matching hover border */
    color: #ffd52f; /* Yellow text on hover */
}

/* Focus effect for keyboard accessibility */
.login_button:focus {
    outline: none; /* Remove default outline */
    box-shadow: 0 0 4px 2px #ffd52f; /* Highlight with yellow glow */
}

/* Active state */
.login_button:active {
    background-color: #003f7f; /* Even darker blue on click */
    border-color: #003f7f; /* Matching border */
    transform: scale(0.98); /* Slight "pressed" effect */
}


.login_error_message {
    color:red;
    font-size:24pt;
    width:600px;
    display:flex;
    flex-direction:row;
    justify-content: center;
}