.chat_main {
    display:flex;
    width:100%;
    flex-direction:column;
    align-items:start;
}
.chat_output_box {
    font-size:14pt;
    width:96%;
    max-width:800px;
    padding:18px;
    color: #0052b6;
    overflow-y: scroll;
    height:300px;
    box-shadow: rgba(0, 123, 255, 0.4) 4px 4px 16px;
    margin-bottom:10px;
}
.chat_input_form {
    display:flex;
    flex-direction:column;
    width:96%;
    max-width:800px;
    box-shadow: rgba(0, 123, 255, 0.4) 4px 4px 16px;
    padding:16px;
}
.chat_input_text {
    border:0;
    background: #f4f4f4;
    padding:10px;
    margin-bottom:3px;
    font-size:18pt;
    width:100%;
}
.chat_button {
    border:0;
    padding:10px;
    margin-bottom:3px;
    background: #ffd52f;
    cursor:pointer;
}
.chat_button:hover {
    background: #b18e24;
}
.chat_line {
    padding:5px;
}



@media (max-width: 992px) {
    .chat_output_box {
        box-shadow: #007bff 0 0;
    }

    .chat_input_form {
        box-shadow: #007bff 0 0;
    }
}