/* Global styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,h1,h2,h3,h4,h5 {
    font-family: 'Roboto', sans-serif;
}
body {
    font-size:16px;
}

#root {
    display: flex;
    flex-direction: column;
}
