/* Mobile-first styles */

.container {
    padding: 16px;
    max-width: 600px;
    margin: auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #fff;
    color: #000;
}

.mainHeading {
    font-size: 1.8em;
    font-weight: bold;
    color: #003366;
    margin-bottom: 16px;
}

.subHeading {
    font-size: 1.4em;
    font-weight: bold;
    color: #0055a5;
    margin-top: 24px;
    margin-bottom: 12px;
}

.sectionHeading {
    font-size: 1.2em;
    font-weight: bold;
    color: #0073e6;
    margin-top: 16px;
    margin-bottom: 8px;
}

.paragraph {
    margin-bottom: 12px;
    font-size: 1em;
}

.example {
    background-color: #f9f9f9;
    border-left: 4px solid #0073e6;
    padding: 8px 12px;
    font-style: italic;
    margin: 12px 0;
}

.list {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 16px;
}

.list li {
    margin-bottom: 8px;
}

.closing {
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    margin-top: 24px;
}

/* Larger screens */
@media (min-width: 768px) {
    .container {
        padding: 24px;
    }

    .mainHeading {
        font-size: 2.4em;
    }

    .subHeading {
        font-size: 1.8em;
    }

    .sectionHeading {
        font-size: 1.6em;
    }

    .paragraph,
    .example {
        font-size: 1.1em;
    }
}
.header_image {
    width:100%;
    border:1px solid black;
}