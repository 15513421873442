/* Container for the entire page */
.container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    /*margin: 20px auto;*/
}

/* Title styling */
.title {
    text-align: center;
    font-size: 24px;
    color: #0066cc;
    margin-bottom: 20px;
}

/* Container for the table */
.table_container {
    max-width: 100%;
    margin: 20px auto;
    padding: 10px;
    overflow-x: auto;
}

/* Table styling */
.table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

/* Header styling */
.th {
    background-color: #0066cc;
    color: white;
    text-align: left;
    padding: 12px;
    font-weight: bold;
    font-size: 14px;
    position: sticky;
    top: 0;
    z-index: 1;
}

/* Row styling */
.tr {
    border-bottom: 1px solid #dddddd;
}

.tr:nth-child(even) {
    background-color: #f9f9f9;
}

.tr:hover {
    background-color: #ffdd00;
    cursor: pointer;
}

/* Cell styling */
.td {
    padding: 12px;
    text-align: left;
    font-size: 14px;
    color: #333333;
    white-space: pre-wrap; /* Preserve line breaks and spacing in log messages */
}

/* Responsive design */
@media (max-width: 768px) {
    .td, .th {
        font-size: 12px;
        padding: 8px;
    }
}

/* Container for the log file list */
.file_list_container {
    max-width: 600px;
    margin: 20px auto;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Individual log file item */
.file_list_item {
    padding: 12px;
    font-size: 14px;
    color: #333333;
    border-bottom: 1px solid #dddddd;
    cursor: pointer;
    transition: all 0.3s ease;
}

/* Hover effect for file items */
.file_list_item:hover {
    background-color: #ffdd00;
    color: #333333;
}

/* Style for the selected file */
.selected_file {
    background-color: #0066cc;
    color: white;
    font-weight: bold;
}
