/* General App styling */
.App {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.App-header {
    background-color: #007bff;
    color: white;
    flex-shrink: 0;
}
.App-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #007bff;
}
.App-main {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;
}
.App-footer {
    text-align: center;
    padding: 10px;
    background-color: #007bff;
    color: white;
}
.logo {
    font-size: 2rem;
    font-weight: bold;
}
.nav-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}
.nav-links li {
    margin-left: 20px;
    cursor: pointer;
}
.nav-links li:hover {
    text-decoration: underline;
}
.welcome_line {
    font-size:22pt;
    text-align:right;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #007bff;
    font-weight:bold;
    text-shadow: #ffd925 2px 2px;
}
.welcome_message {
    padding-left:15px;
    color: #ffd925;
}

/* COLORS */
.umkc_blue {
    background: #007bff!important;
    color:white!important;
}
.red {
    color: #fff !important;
    background-color: #f44336 !important;
}
.text_umkc_blue {
    color:#007bff!important;
}
.text_umkc_yellow {
    color:#ffd52f!important;
}

/* MAIN PAGE */
.main {
    transition:margin-left .4s;
    width: calc(100% - 240px);
    margin-left:220px;
    display:flex;
    flex-direction:column;
}
.banner {
    width: 100%;
    overflow: hidden;
    background: #FFdd00;
}
.banner-image {
    width: 100%;
    height: auto;

}
.header {
    position: fixed;
    width: 100%;
    z-index: 2;
    font-size: 24px !important;
    top:0;
}
.sidebar {
    height: 100%;
    width: 200px;
    position: fixed !important;
    z-index: 2;
    overflow: auto;
    top:0;
    font-size:18px!important;
    padding:8px 16px!important;
    left:0;
}
.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}
.banner_image {
    top:0;
    height:200px;
    min-height:200px;
    width:100%;
    max-height:200px;
    overflow: hidden;
    background-image: url("../images/banner.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.round {
    border-radius:4px;
}
.top, .bottom {
    position: fixed;
    width: 100%;
    z-index: 1
}
.top {
    top: 0
}
.bottom {
    bottom: 0
}
.button {
    border: none;
    display: inline-block;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
}
.bar_block {
    text-align: left;
    padding: 8px 0;
    min-width: 100%;
    display: block;
    border: none;
    white-space: normal;
    float: none;
    outline: 0;
}
.bar_item {
    width: 100%;
    display: block;
    padding: 8px 16px;
    text-align: left;
    border: none;
    white-space: normal;
    float: none;
    outline: 0;
}
.hover_white:hover {
    color:#000!important;background-color:#fff!important;
}
.display_topleft {
    position: absolute;
    left: 0;
    top: 0;
}
.container, .panel {
    padding: 0.01em 16px;
}
.panel {
    margin-top: 16px;
    margin-bottom: 16px;
}
.sidebar_open_button {
    margin-right:16px!important;
}
.container:after, .container:before, .panel:after, .panel:before, .row:after, .row:before, .row-padding:after, .row-padding:before,
.cell-row:before, .cell-row:after, .clear:after, .clear:before, .bar:before, .bar:after {
    content: "";
    display: table;
    clear: both
}
.hidden {
    display:none;
}

/* LOGOUT MODAL */
.logout_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.logout_modal_modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.logout_modal_buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.logout_modal_logout_button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.logout_modal_stay_button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

/* SIZES */
.tiny {
    font-size: 10px !important
}
.small {
    font-size: 12px !important
}
.medium {
    font-size: 15px !important
}
.large {
    font-size: 18px !important
}
.xlarge {
    font-size: 24px !important;
}
.jumbo {
    font-size: 64px !important;
}
.xxxlarge {
    font-size: 48px !important;
}

/* PADDING */
.padding {
    padding: 8px 16px !important
}
.padding_64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
}

/* CUSTOMIZATION FOR SPECIFIC SCREEN SIZES */
@media (min-width: 993px) {
    .modal-content {
        width: 900px
    }

    .hide_large {
        display: none !important
    }

    .sidebar_collapse {
        display: block !important
    }
}
@media (max-width: 992px) and (min-width: 601px) {
    .hide-medium {
        display: none !important
    }
}
@media (max-width: 992px) {
    .sidebar_collapse {
        display: none
    }

    .main {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .auto {
        max-width: 100%
    }

    .welcome_line {
        top: 60px;
    }
}



