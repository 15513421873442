.container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    /*margin: 20px auto;*/
}

/* Title styling */
.title {
    /*text-align: center;*/
    font-size: 24px;
    color: #0066cc;
    margin-bottom: 20px;
}